import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import AboutHead from "../partials/AboutHead";
import WhatPeopleSay from "../partials/WhatPeopleSay";
import Social2 from "../partials/Social2";
import Team from "../partials/Team";

export default function AboutPage() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<AboutHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-5">
            <p className="mb-4">
              Patients of all ages can receive high-quality dental care at
              Killeen Family Dentistry, located in Killeen,Texas.The dental team
              emphasizes compassionate and gentle care, which helps children and
              adults alike feel more at ease about having dental work done.
              Patients can count on being treated with kindness while also
              receiving superior quality dental care.
            </p>
            <p className="mb-4">
              The dentists at Killeen Family Dentistry offer a wide range of
              dental services to help patients keep their teeth and gums in
              excellent shape. Some of the available services include preventive
              care, pediatric dentistry, teeth whitening and other cosmetic
              dentistry. Patients receive care in a
              state-of-the-art facility from providers who excel at ensuring
              that they feel as comfortable as possible during their visit.
            </p>
            <p className="mb-4">
              The dental team at Killeen Family Dentistry understands that
              patients can be nervous about dental visits, so they provide
              gentle dentistry that doesn't cause patients any considerable
              discomfort. Doing so helps encourage patients to make sure they
              get the dental care they need for good oral health, which can
              improve their physical and emotional well-being.
            </p>
          </div>
        </div>
        {<Team />}
        {<Social2 />}
        {<WhatPeopleSay />}
      </main>
      {<Footer />}
    </div>
  );
}
