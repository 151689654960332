import React from "react";
import Img1 from "../images/clients-01.svg";
import Img2 from "../images/clients-04.svg";
import Img3 from "../images/clients-05.svg";
import Img4 from "../images/clients-02.svg";
import Img5 from "../images/clients-03.svg";
import CTA from "../images/CTA.svg";
import { Link } from "react-router-dom";

function Connect() {
  return (
    <section
      id="connect"
      className="section relative max-w-6xl max-w-8xl mx-auto px-4"
    >
      <div className="clients d-flex justify-content-center">
        <div className="">
          <div data-aos="fade-down" data-aos-delay="0" className="d-flex mobile-flex justify-content-center align-items-center">
            <span className="m-f-14">
              Trusted by more than&nbsp;<b>15+</b>&nbsp;Insurance Company
              &nbsp;&nbsp;
            </span>
            <Link to="/about" className="text-primary">
              <img className="cta-class" src={CTA} alt="Killeen Family Dentistry" />
            </Link>
          </div>
          <ul className="list-reset d-flex justify-content-center mb-5 mobile-flex-wrap">
            <li data-aos="fade-down" data-aos-delay="50" className="reveal-from-bottom">
              <img src={Img1} alt="Clients 01" />
            </li>
            <li data-aos="fade-down" data-aos-delay="100" className="reveal-from-bottom" data-reveal-delay="150">
              <img src={Img2} alt="Clients 02" />
            </li>
            <li data-aos="fade-down" data-aos-delay="150" className="reveal-from-bottom" data-reveal-delay="300">
              <img src={Img3} alt="Clients 03" />
            </li>
            <li data-aos="fade-down" data-aos-delay="200" className="reveal-from-bottom" data-reveal-delay="450">
              <img src={Img4} alt="Clients 04" />
            </li>
            <li data-aos="fade-down" data-aos-delay="250" className="reveal-from-bottom" data-reveal-delay="600">
              <img src={Img5} alt="Clients 05" />
            </li>
          </ul>
        </div>
      </div>
      <hr></hr>
    </section>
  );
}

export default Connect;
