import React from "react";
import Img1 from "../images/Logo01.png";
import Img2 from "../images/Logo02.png";
import Img3 from "../images/Logo03.png";
import Img4 from "../images/Logo04.png";
import Img5 from "../images/Logo05.png";
import Img6 from "../images/Logo06.png";
import Img7 from "../images/Logo07.png";
import Img8 from "../images/Logo08.png";
import Img9 from "../images/Logo09.png";
import Img10 from "../images/Logo10.png";

function Social2() {
  return (
    <section id="process" className="pt-5 process_container pb-4 mb-5rem">
      <div className="max-w-6xl max-w-8xl mx-auto pt-4 px-4 sm:px-6">
        <div
          className="max-w-3xl mx-auto text-center pb-12 md:pb-20 row justify-content-center"
          style={{ color: "black" }}
        >
          <h2 className="h2 mb-4" data-aos="fade-up">
            Most Insurances Accepted
          </h2>
          <p>
          At Killeen Family Dentistry, we take almost every insurance and are also in-network with almost 
          every insurance. Here is a list of some of the plans we accept.{" "}
          </p>
        </div>
      </div>
      <div className="max-w-6xl max-w-8xl mx-auto px-4 container">
        <ul data-aos="zoom-in-up" className="list-reset d-flex justify-content-center mb-5 mobile-flex-wrap">
          <li className="">
            <img className="m-4" src={Img1} alt="Clients 01" />
          </li>
          <li className="" data-reveal-delay="150">
            <img className="m-4" src={Img2} alt="Clients 02" />
          </li>
          <li className="" data-reveal-delay="300">
            <img className="m-4" src={Img3} alt="Clients 03" />
          </li>
          <li className="" data-reveal-delay="450">
            <img className="m-4" src={Img4} alt="Clients 04" />
          </li>
          <li className="" data-reveal-delay="600">
            <img className="m-4" src={Img5} alt="Clients 05" />
          </li>
        </ul>
        <ul data-aos="zoom-in-up" className="list-reset d-flex justify-content-center mb-5 mobile-flex-wrap">
          <li className="">
            <img className="m-4" src={Img6} alt="Clients 06" />
          </li>
          <li className="" data-reveal-delay="150">
            <img className="m-4" src={Img7} alt="Clients 07" />
          </li>
          <li className="" data-reveal-delay="300">
            <img className="m-4" src={Img8} alt="Clients 08" />
          </li>
          <li className="" data-reveal-delay="450">
            <img className="m-4" src={Img9} alt="Clients 09" />
          </li>
          <li className="" data-reveal-delay="600">
            <img className="m-4" src={Img10} alt="Clients 10" />
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Social2;
