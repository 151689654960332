import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import OtherHeader from "../partials/OtherHeader";
import IMG2 from "../images/access-state-svg.png";

const AccessibilityStatement = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<OtherHeader />}
      <main className="grow">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-5">
            <p>
              {" "}
              We are committed to providing a website that is accessible to
              individuals with disabilities, in conformance with the{" "}
              <a href="#0" className="text-primary">
                <u>Web Content Accessibility Guidelines (WCAG) 2.1,</u>
              </a>{" "}
              Level A & AA. <br />
              <br />
              To this end, we regularly test our website in accordance with best
              practices, using automated and manual testing and with the
              assistance of accessibility consultant eSSENTIALAccessibility
              which is supported by a diverse team of accessibility
              professionals including users of assistive technologies.We also
              make available an assistive CX technology application which our
              customers who have trouble typing, gesturing, moving a mouse,or
              reading can use for additional accessibility, however it is not
              required to use our website.The application is free to download
              and it incorporates tools such as mouse and keyboard
              replacements,voice recognition,speech enablement,
              hands-free/touch-free navigation, and more.
              <br />
              <br />
              We welcome yourfeedback.lf you encounter any accessibility
              barriers or otherwise have difficulty using our website due your
              disability, please contact our office or reach out to our website
              provider at 844-487-8399 for assistance.{" "}
            </p>
          </div>
          <div className="mb-5">
            <div className="row accessibilty-bg px-5">
              <div className="col-lg-9 col-md-9 col-sm-9">
                <h2 className="h3 mt-5">Install free assistive technolgogy </h2>
                <p className="mb-5 text-white">
                  The technology suite of keyboard, mouse & touch replacement
                  solutions <br /> designed to make the digital world accessible
                  to people with physical disabilities.
                </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 pt-2 mt-5">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.essentialaccessibility.com/patientpop?utm_source=patientpophomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=patientpop"
                >
                  <img
                    src={IMG2}
                    className="accessibilty-svg"
                    alt="accessibilty-svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
};

export default AccessibilityStatement;
