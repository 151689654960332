import React from "react";
import Img1 from "../images/process-Icon.png";
import Img2 from "../images/p2.png";
import Img3 from "../images/p3.png";
import Img4 from "../images/Signup.svg";
import Img5 from "../images/Downloads.png";
import Img6 from "../images/Icon.png";

function SubBenefits() {
  return (
    <section id="process" className="pt-5 mt-5">
      <div className="max-w-6xl max-w-8xl mx-auto px-4 sm:px-6 pb-5">
        <img className="p1-img" src={Img3} alt="" />
        <img className="p2-img" src={Img2} alt="" />
        <div className="pt-10 pb-12 md:pt-16 md:pb-20 pt-5">
          {/* Section header */}
          <div
            className="max-w-3xl process-head mx-auto text-center md:pb-20 row justify-content-center"
            style={{ color: "black" }}
          >
            <img className="p-Icon" src={Img1} alt="Icon" />
            <h2 className="h2 mb-4 process-heading" data-aos="fade-up">
              Benefits Include
            </h2>
          </div>
          <p className="justify-center text-center pb-12 d-flex">
            Comprehensive Dental Care for You and Your Family at a DISCOUNT
          </p>

          <div className="d-flex mob-process">
            {/* 1st item */}
            <div
              className="relative flex w-33percent flex-col items-center process-cont"
              data-aos="fade-up"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
                data-aos="fade-in"
                data-aos-delay="200"
              ></div>
              <img className="process-icons" src={Img4} alt="Icon" />
              <h3 className="h4 mt-4 mb-2" style={{ color: "black" }}>
                1. Professional Cleaning
              </h3>
              <p
                className="text-lg text-center"
                style={{ color: "rgba(29, 38, 58, 0.8)" }}
              >
                Using specialized dental tools, one of our skilled team members
                will gently remove plaque and other debris from your teeth and
                gums, making it easier to properly assess your dental health.
              </p>
            </div>

            {/* 2nd item */}
            <div
              className="relative flex w-33percent flex-col items-center process-cont"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
                data-aos="fade-in"
                data-aos-delay="400"
              ></div>
              <img className="process-icons" src={Img5} alt="Icon" />
              <h3 className="h4 mt-4 mb-2" style={{ color: "black" }}>
                2. Thorough Exam
              </h3>
              <p
                className="text-lg text-center mx-5"
                style={{ color: "rgba(29, 38, 58, 0.8)" }}
              >
                Our Team will perform a meticulous evaluation of your teeth and
                gums to understand the state of your oral health. They will then
                go over any concerns with you in a detailed, easy-to-understand
                way.
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex w-33percent flex-col items-center process-cont"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <img className="process-icons" src={Img6} alt="Icon" />
              <h3 className="h4 mt-4 mb-2" style={{ color: "black" }}>
                3. All Necessary X-Rays
              </h3>
              <p
                className="text-lg  text-center"
                style={{ color: "rgba(29, 38, 58, 0.8)" }}
              >
                X-rays are not only completely safe, but are also crucial to
                attaining a full view of your dental health. X-rays allow us to
                see below the surface, exposing issues that may otherwise go unnoticed.
              </p>
            </div>
          </div>
          <div className="d-flex mt-3rem">
            <a
              href="/#"
              target="_blank"
              rel="noreferrer"
              className="btn-enroll mx-auto text-white bg-blue ml-6"
            >
              254-242-0964
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubBenefits;
