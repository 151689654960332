import React from "react";
import { Link } from "react-router-dom";
import Img2 from "../images/service-pic.png";
import CTA from "../images/CTA.svg";

function HomeService() {
  return (
    <section
      id="home-service"
      className="section relative max-w-6xl max-w-8xl mx-auto px-4 py-5"
    >
      <div className="split-item my-4">
        <div
          className="split-item-content center-content-mobile reveal-from-right"
          data-reveal-container=".split-item"
        >
          <div className="d-flex mob-service">
            <div className="w-50">
              <h2 data-aos="fade-left" data-aos-delay="50" className="mt-0 h2 mb-12">Our Services</h2>
              <div className="mr-5 pt-4">
                <p className="text-gray-400 mr-5 w-200">
                  The dentists at Killeen Family Dentistry offer a wide range of
                  dental services to help patients keep their teeth and gums in
                  excellent shape. Some of the available services include
                  preventive care, pediatric dentistry, teeth whitening and
                  other cosmetic dentistry.
                </p>
                <br />
                <p className="text-gray-400 mb-4 mr-5 w-200">
                  Patients receive care in a state-of-the-art facility from
                  providers who excel at ensuring that they feel as comfortable
                  as possible during their visit.
                </p>
                <br />
                <div className="list-ipad">
                  <div data-aos="fade-left" data-aos-delay="50" className="d-flex mb-4">
                    <span className="rect"></span>
                    <span className="rect-2"></span>
                    <span className="rect-3"></span>
                    <h4  className="w-50">Fillings</h4>
                    <span className="rect"></span>
                    <span className="rect-2"></span>
                    <span className="rect-3"></span>
                    <h4 className="w-50">Bridges</h4>
                  </div>
                  <div data-aos="fade-right" data-aos-delay="50" className="d-flex mb-4">
                    <span className="rect"></span>
                    <span className="rect-2"></span>
                    <span className="rect-3"></span>
                    <h4 className="w-50">Crowns</h4>
                    <span className="rect"></span>
                    <span className="rect-2"></span>
                    <span className="rect-3"></span>
                    <h4 className="w-50">Dentures</h4>
                  </div>
                  <div data-aos="fade-left" data-aos-delay="50" className="d-flex mb-4">
                    <span className="rect"></span>
                    <span className="rect-2"></span>
                    <span className="rect-3"></span>
                    <h4 className="w-50">Exractions</h4>
                    <span className="rect"></span>
                    <span className="rect-2"></span>
                    <span className="rect-3"></span>
                    <h4 className="w-50">Peridontics</h4>
                  </div>
                </div>
                <br />
                <div>
                  <Link className="text-primary" to="/services">
                    <img className="cta-class" src={CTA} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div data-aos="fade-up-left" data-aos-delay="50" className=" w-50 service-i-img">
              {/* <img className='background-img' src="../images/illustration-element-03.svg" alt="" />
                            <img className='img-margin' src={Img1} alt="" /> */}
              <img src={Img2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeService;
