import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";
const data = [
  {
    key: 1,
    title: "What is a dental emergency?",
    content:<div className="services-text">
    <p>The term dental emergency is a comprehensive one that covers any critical issue involving the teeth, gum or other supporting tissue. Pain is a good indicator of a dental emergency. </p>
    <p>If a child is clutching their cheek and crying because something in his or her mouth hurts, that's an emergency. Loss of a tooth -- whether it's a permanent tooth or a prematurely lost baby tooth -- is another emergency problem.</p>
    <p>If parents or adult patients are not sure if they have a dental emergency, they can call the office at Killeen Family Dentistry and speak to someone there for first aid assistance and instructions on how to see a dentist.</p>
    </div>
  },
  {
    key: 2,
    title: "What are some common dental emergencies?",
    content:<div className="services-text">
    <p>It's always best to call and ask if a problem needs immediate attention, especially when it comes to a child involved in an accident. Some common emergencies seen at Killeen Family Dentistry include:</p>
      <ul className="disc">
        <li>Knocked-out tooth</li>
        <li>Cracked tooth</li>
        <li>Gum, mouth or tongue bleeding that doesn’t stop</li>
        <li>Toothaches</li>
        <li>Facial swelling</li>
        <li>Object stuck between teeth</li>
        <li>Dental intrusion</li>
        <li>Tooth displacement</li>
        <li>Fractured tooth</li>
        <li>Dental concussion that causes the tooth to blacken</li>
      </ul>
    <p>Any of these issues are emergencies that need immediate assessment and treatment.</p>
    </div>
  },
  {
    key: 3,
    title: "What is a dental intrusion?",
    content:<div className="services-text">
      <p>Dental intrusion is a common injury for kids who love to run around or play sports. The impact of a fall or sports accident pushes a tooth up into the jawbone. In some cases, the tooth may descend naturally, but often the tooth fractures in the socket and requires treatment.</p>
    </div>
  },
  {
    key: 4,
    title: "What is a dental concussion?",
    content:<div className="services-text">
    <p>A dental concussion is an injury that tends to affect toddlers who fall a lot or bump into things.</p>
    <p>The impact of the fall damages the supporting structure even though the tooth remains intact. In most cases, a dental concussion causes pain and changes the way the tooth looks. It may darken slightly, for example. </p>
    <p>The discoloration is sometimes temporary, but it can be permanent. If the tooth turns black, it's dying, and the child needs immediate dental treatment.</p>
    </div>
  } 
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-2">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && <p data-aos="fade-up" className="accordion-content pt-4 pb-1">{content}</p>}
      </div>
    </div>
  );
};

export default function EmergencyDentistry() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
            Sometimes, a person can’t afford to wait for days to see the dentist. That's why Killeen Family Dentistry in Killeen, Texas proudly offers families emergency dentistry because some things can’t wait. Through their emergency dentistry service, patients can call the office and know they will be seen right away if necessary. Emergency dentistry is a 24/7, 365-days-a-year service. If you or a child in your care has a dental emergency, call the Killeen Family Dentistry office for assistance.
            </p>
          </div>
          <div className="mt-2rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
