import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";

const data = [
  {
    key: 1,
    title: "Why might I need a filling?",
    content:<div className="services-text">
    <p>Your dentist at Killeen Family Dentistry checks your teeth during regular exams and when you come in for certain symptoms, such as pain in the affected tooth.</p>      
    <p>If there’s evidence of tooth decay, such as holes in your teeth, your dentist fills them to protect your tooth. Fillings lower your risk of having tooth infections and other serious oral health problems.</p>
    </div>
  },
  {
    key: 2,
    title: "What are fillings made from?",
    content:<div className="services-text">
    <p>If you’re worried about having highly visible metal fillings in your mouth, you don’t need to be. Fillings these days are often made from materials that resemble natural teeth, such as porcelain or composite resin, so they’re not as noticeable to other people.</p>      
    <p>Your dentist at Killeen Family Dentistry will go over available filling materials with you to help you choose the right one based on cost and your personal preferences.</p>
    </div>
  },
  {
    key: 3,
    title: "What happens when I get a filling?",
    content:<div className="services-text">
    <p>Your dentist will use local anesthesia to numb the area around the affected tooth so that you won’t experience any discomfort.</p>      
    <p>After doing this, your dentist removes the decayed part of your tooth and put a filling in its place. This filling helps restore your tooth’s functionality while also protecting it from further decay and bacteria.</p>
    <p>In some cases, such as when you’re waiting for a crown, you might need to have a temporary filling to protect your tooth.</p>
    </div>
  },
  {
    key: 4,
    title: "Do fillings hurt?",
    content:<div className="services-text">
    <p>Getting a filling shouldn’t hurt since your dentist uses local anesthesia.</p>      
    <p>As the anesthesia wears off, you might have slight discomfort and experience temporary sensitivity. It can last for a couple of weeks, but you should let your dentist at Killeen Family Dentistry know if it gets worse or doesn’t subside. You can take over-the-counter pain medications during that time to ease discomfort.</p>
    </div>
  },
  {
    key: 5,
    title: "Do fillings need special care?",
    content:<div className="services-text">
    <p>You don’t need to do anything different to take care of your fillings. Practicing good oral hygiene with proper brushing and flossing should help them stay in excellent condition.</p>      
    <p>However, fillings can start to wear away over time, although this usually takes several years. You should let your dentist know if you lose a filling or have one that becomes loose or damaged.</p>
    <p>If you have tooth decay and need fillings, please contact Killeen Family Dentistry to schedule an appointment.</p>
    </div>
  },
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-4">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && <p data-aos="fade-up" className="accordion-content pt-4 pb-4">{content}</p>}
      </div>
    </div>
  );
};

export default function Fillings() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
              When you have a hole in your tooth due to decay, bacteria can
              enter and lead to an infection. Filling these holes helps prevent
              this from happening and keeps your tooth more intact. Fillings are
              among the most common types of dental care.At Killeen Family
              Dentistry, located in Killeen,Texas,the dental team may recommend
              fillings for patients both young and old who have tooth decay.
            </p>
          </div>
          <div className="mt-10rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
