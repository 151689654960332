import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "../utils/Dropdown";
import Transition from "../utils/Transition";
import Logo from "../images/Killen Logo.png";
import Call from "../images/call 1.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BookAppointment from "./BookAppointment";

function Header() {
  const [isShown, setIsShown] = React.useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="absolute w-full z-30">
      <div className="mx-auto px-4">
        <div className="flex items-center justify-content-center mobile-justify-between h-20">
          {/* Site branding */}
          <div className="d-flex shrink-0 mr-5">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              <img className="Killen-Logo" src={Logo} alt="Logo" />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="mobile-hidden flex grow lg:flex lg:grow">
            {/* Desktop menu links */}
            <ul className="flex grow flex-wrap items-center px-4 justify-content-center font-medium">
              <li>
                <Link
                  to="/about"
                  className="header-text  hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="header-text  hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Services
                </Link>
              </li>
              <Dropdown className="header-text w-20rem" title="For Patients">
                <li>
                  <Link
                    to="/pricing"
                    className="header-text text-sm  dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
                  >
                    New Patient Special
                  </Link>
                </li>
                <li>
                  <a
                    href="https://killeendentistry.subscribili.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="header-text text-sm  dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
                  >
                    Killeen Family Dentistry Membership
                  </a>
                </li>
              </Dropdown>
              <li>
                <Link
                  to="/contact"
                  className="header-text hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Contact
                </Link>
              </li>
            </ul>

            {/* Desktop CTA on the right */}
            <ul className="flex justify-end flex-wrap items-center">
              <li className="d-flex align-items-center">
                <img className="mr-2 call-1 call-icon" src={Call} alt="call" />
                <span className="hover-blue">(254) 218-6349</span>
              </li>
              <li>
                <button
                  onClick={handleClick}
                  className="btn-sm text-white bg-blue ml-6"
                >
                  <p className="text-white">Book Appointment</p>
                </button>
              </li>
              <Dialog
                maxWidth="md"
                fullWidth={true}
                open={isShown}
                onClose={handleClick}
              >
                <DialogContent>{<BookAppointment />}</DialogContent>
              </Dialog>
            </ul>
          </nav>

          <div className="inline-flex md-hidden">
            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <Transition
              show={mobileNavOpen}
              tag="ul"
              className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
              enter="transition ease-out duration-200 transform"
              enterStart="opacity-0 -translate-x-full"
              enterEnd="opacity-100 translate-x-0"
              leave="transition ease-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
            >
              <nav
                id="mobile-nav"
                ref={mobileNav}
                className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar"
              >
                <div className="py-6 pr-4 pl-20">
                  {/* Logo */}
                  <img className="Killen-Logo" src={Logo} alt="Logo" />
                  {/* Links */}
                  <ul>
                    <li>
                      <Link
                        to="/about"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        Services
                      </Link>
                    </li>
                    <li className="py-2 my-2 border-t border-b border-gray-200 dark:border-gray-800">
                      <span className="flex text-gray-600 dark:text-gray-400 py-2">
                        For Patients
                      </span>
                      <ul className="pl-4">
                        <li>
                          <Link
                            to="/pricing"
                            className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            New Patient Special
                          </Link>
                        </li>
                        <li>
                          <a
                            href="https://killeendentistry.subscribili.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            Killeen Family Dentistry Membership
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link
                        to="/contact"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </Transition>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
