import React from "react";
import { Link } from "react-router-dom";
import HeroImage from "../images/hero-image-01.jpg";
import HeroImage2 from "../images/placeholder.png";
import BulletIcon from "../images/bulleticon.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useLocation } from "react-router-dom";
import BookAppointment from "./BookAppointment";

export default function HeroHome() {
  const [isShown, setIsShown] = React.useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  const location = useLocation();
  return (
    <section className="relative h-35rem">
      {/* Dark background */}
      <div
        className="absolute inset-0 bg-color bg-slate-900 pointer-events-none -z-10 "
        aria-hidden="true"
      ></div>

      <div className="relative max-w-6xl max-w-8xl mx-auto px-4 m-w-100">
        <div className="pt-32 pb-20 pt-40 pb-44">
          {/* Hero content */}
          <div className="m-w-100 mac-justify-between mobile-flex mx-auto max-w-none flex items-center">
            {/* Content */}
            <div className="w-50 m-w-100 text-left">
              <div data-aos="fade-left">
                {location.pathname === "/home" ? (
                  <h1 className="font-playfair-display mb-4">
                    Getting a beautiful smile at{" "}
                    <span className="affordable"> affordable prices</span>{" "}
                  </h1>
                ) : (
                  ""
                )}
                {location.pathname === "/services" ? (
                  <h1 className="h1 font-playfair-display mb-4">
                    A wide variety of{" "}
                    <span className="affordable">services</span> that we offer
                  </h1>
                ) : (
                  ""
                )}
                {location.pathname === "/pricing" ? (
                  <h1
                    className="h1 font-playfair-display mb-4"
                    style={{ height: "150px" }}
                  >
                    Looking for a<br></br>
                    <span className="affordable">Dentist</span> in Killeen?
                  </h1>
                ) : (
                  ""
                )}
              </div>
              {location.pathname === "/home" ? (
                <p className="gordita-regular mb-8 mr-12">
                  Killeen family Dentistry, experience the best of dentistry and
                  perfect your smile.
                </p>
              ) : (
                ""
              )}
              {location.pathname === "/services" ? (
                <p className="gordita-regular mb-8 mr-12">
                  We offer comprehensive services, with compassionate care to
                  meet all your dental needs.
                </p>
              ) : (
                ""
              )}
              {location.pathname === "/pricing" ? (
                <p
                  className="gordita-regular mb-8 mr-12"
                  style={{ height: "150px" }}
                >
                  There is no better time than now to begin your journey to
                  long-lasting oral health and well-being. Our $200 New Patient
                  Special includes everything you need to start your dental
                  relationship with our expert team, and to experience the
                  Killeen Family Dentistry difference.
                </p>
              ) : (
                ""
              )}
              <div className="m-w-100 max-w-s mx-auto flex space-y-4">
                <div>
                  <button
                    onClick={handleClick}
                    className="btn-schedule btn text-white bg-blue-600 w-full group mr-2"
                  >
                    Book Appointment
                  </button>
                </div>
                <Dialog
                  maxWidth="md"
                  fullWidth={true}
                  open={isShown}
                  onClose={handleClick}
                >
                  <DialogContent>{<BookAppointment />}</DialogContent>
                </Dialog>
                <div>
                  <a
                    className="btn-dental ml-3 btn text-white bg-slate-700 w-full"
                    href="https://killeendentistry.subscribili.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dental Plans
                  </a>
                </div>
              </div>
            </div>

            {/* Hero image */}
            <div
              className="mac-mr-1rem m-w-100 m-mt-2rem"
              data-aos="w-50 fade-left"
            >
              <div className="flex justify-center items-center">
                <div data-aos="zoom-in-up" className="relative">
                  <div
                    className="absolute inset-0 pointer-events-none border-2 border-slate-700 mt-3 ml-3 translate-x-4 translate-y-4 -z-10"
                    aria-hidden="true"
                  ></div>
                  {location.pathname === "/home" ? (
                    <img
                      className="mx-auto max-w-none"
                      src={HeroImage}
                      width="540"
                      height="405"
                      alt="Hero"
                    />
                  ) : (
                    ""
                  )}
                  {location.pathname === "/services" ? (
                    <img
                      className="mx-auto max-w-none"
                      src={HeroImage2}
                      width="540"
                      height="405"
                      alt="Hero"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {location.pathname === "/pricing" ? (
              <div className="col-lg-6 col-12">
                <div className="col-lg-9 col-sm-11 offset-lg-3">
                  <div className="shadow-sm card card-price mb-3 p-3">
                    <div className="card-body">
                      <h2 className="text-primary price-text card-title">
                        $200
                      </h2>
                      <p>NEW PATIENT SPECIAL </p>
                      <br />
                      <br />
                      <div>
                        <Link
                          className="btn-enroll mx-auto text-white bg-blue ml-6"
                          to="/pricing"
                        >
                          254-242-0964
                        </Link>
                      </div>
                      <br />
                      <br />
                      <hr />
                      <br />
                      <div className="card-text">
                        <div className="d-flex">
                          <img
                            className="mr-2"
                            width="20px"
                            src={BulletIcon}
                            alt="Icon"
                          />{" "}
                          Cleaning
                        </div>
                        <div className="d-flex mt-2 mb-2">
                          <img
                            className="mr-2"
                            width="20px"
                            src={BulletIcon}
                            alt="Icon"
                          />{" "}
                          Exams
                        </div>
                        <div className="d-flex">
                          <img
                            className="mr-2"
                            width="20px"
                            src={BulletIcon}
                            alt="Icon"
                          />{" "}
                          X-Rays
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
