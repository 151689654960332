import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";
const data = [
  {
    key: 1,
    title: "What is a tooth extraction?",
    content:<div className="services-text">
    <p>Tooth extraction means removing a tooth from the mouth.</p>      
    <p>It's among the least popular dental procedures do but one that's sometimes necessary. There are many reasons one of the dentists at Killeen Family Dentistry might recommend this procedure.</p>
    <p>Teens, for example, often undergo wisdom teeth removal, which is the extraction of up to four teeth. Other common reasons for tooth extraction include:</p>
    <ul className="disc"><li>A fractured tooth</li><li>A severely infected tooth</li><li>An impacted tooth</li><li>Advanced gum disease</li></ul>
    <p>It’s always preferable to save the natural tooth, but when that’s not possible, it’s essential to remove it.</p>
    </div>
  },
  {
    key: 2,
    title: "What happens after the tooth is out?",
    content:<div className="services-text">
    <p>That depends on numerous factors, including why it was extracted.</p>      
    <p>Often the remaining teeth will shift to fill the void, especially if overcrowding is the reason the dentist suggested pulling the tooth. As such, patients will sometimes have another procedure done to replace the tooth, too.</p>
    <p>Many opt for dental implants, for instance, to put a new, prosthetic tooth in that socket and avoid the shifting of the remaining teeth. If the problem tooth is in the back, the wisdom tooth, replacement might not be necessary.</p>
    </div>
  },
  {
    key: 3,
    title: "Does a tooth extraction hurt?",
    content:<div className="services-text">
    <p>The truth is, it sounds worse than it is for most people. Killeen Family Dentistry offers sedation options that can make it more comfortable for their patients.</p>      
    <p>Once the tooth is out, the patient will bite on gauze for about 30 minutes to keep the bleeding in check. The staff will work with patients to help prevent complications of tooth extraction, such as an infection, by providing post-procedure instructions. A cold pack will help relieve some of the pain after the procedure.</p>
    <p>Patients should come to the office wearing comfortable clothing to make the trip home more comfortable. They should also arrange for someone else to take them home, and stick to soft foods for a while (tooth extractions mean plenty of pudding for kids).</p>
    </div>
  }
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-2">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && <p data-aos="fade-up" className="accordion-content pt-4 pb-1">{content}</p>}
      </div>
    </div>
  );
};

export default function Extractions() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
            Nobody likes getting a tooth pulled, but sometimes it is necessary. The dental professionals at Killeen Family Dentistry in Killeen, Texas work with patients, so they understand how the procedure and why they need it. Patient education is an integral part of managing anxiety, but the office also offers sedation options for the patient to help put them at ease. If you or a member of your family needs a tooth pulled, click or call for more information and a consultation.
            </p>
          </div>
          <div className="mt-10rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
