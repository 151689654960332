import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import OtherHeader from "../partials/OtherHeader";

export default function TermCondition() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<OtherHeader />}
      <main className="grow">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4">
              {" "}
              This Practice Web Site is offered to you conditioned on your
              acceptance without modification of the terms, conditions, and
              notices contained herein.Your use of this Practice Web Site
              constitutes your agreement to all such terms, conditions,and
              notices.
            </p>
          </div>
          <div className="mb-3">
            <h2 className="mb-3 h4 mt-5">Modification of these terms of use</h2>
            <p className="mb-4">
              {" "}
              This Practice reserves the right to change the terms, conditions,
              and notices under which this Practice Web Site is offered,
              including but not limited to the charges associated with the use
              of this Practice Web Site.
            </p>
          </div>
          <div className="mb-3">
            <h2 className="mb-3 h4 mt-5">Links to third party sites</h2>
            <p className="mb-4">
              This Practice Web Site may contain links to other Web Sites
              ("Linked Sites").The Linked Sites are not under the control of
              this Practice and this Practice is not responsible for the
              contents of any Linked Site, including without limitation any link
              contained in a Linked Site,or any changes or updates to a Linked
              Site.This Practice is not responsible for webcasting or any other
              form of transmission received from any Linked Site.This Practice
              is providing these links to you only as a convenience,and the
              inclusion of any link does not imply endorsement by this Practice
              of the site or any association with its operators.
            </p>
          </div>
          <div className="mb-3">
            <h2 className="mb-3 h4 mt-5">No unlawful or prohibited use</h2>
            <p className="mb-4">
              As a condition of your use of this Practice Web Site,you warrant
              to this Practice that you will not use this Practice Web Site for
              any purpose that is unlawful or prohibited by these terms,
              conditions, and notices.You may not use this Practice Web Site in
              any manner which could damage, disable, overburden, or impair this
              Practice Web Site or interfere with any other party's use and
              enjoyment of this Practice Web Site.You may not obtain or attempt
              to obtain any materials or information through any means not
              intentionally made available or provided for through this Practice
              Web Sites.
            </p>
          </div>
          <div className="mb-3">
            <h2 className="mb-3 h4 mt-5">Use of communication services</h2>
            <p className="mb-4">
              This Practice Web Site may contain bulletin board services, chat
              areas,news groups, forums, communities, personal web pages,
              calendars, and/or other message or communication facilities
              designed to enable you to communicate with the public at large or
              with a group (collectively,"Communication Services"),you agree to
              use the Communication Services only to post, send and receive
              messages and material that are proper and related to the
              particular Communication Service. By way of example, and not as a
              limitation,you agree that when using a Communication Service,you
              will not:
            </p>
            <ul className="mt-5 d-flex flex-column mx-auto w-95">
              <li className="mb-3">
                <p>
                  • Defame, abuse, harass, stalk, threaten or otherwise violate
                  the legal rights (such as rights of privacy and publicity) of
                  others.
                </p>
              </li>
              <li className="mb-3">
                <p>
                  {" "}
                  • Publish, post, upload, distribute or disseminate any
                  inappropriate, profane, defamatory, infringing, obscene,
                  indecent or unlawful topic, name, material or information.
                </p>
              </li>
              <li className="mb-3">
                <p>
                  • Upload files that contain software or other material
                  protected by intellectual property laws (or by rights of
                  privacy of publicity) unless you own or control the rights
                  thereto or have received all necessary consents.
                </p>
              </li>
              <li className="mb-3">
                <p>
                  • Upload files that contain viruses,corrupted files, or any
                  other similar software or programs that may damage the
                  operation of another's computer.
                </p>
              </li>
              <li className="mb-3">
                <p>
                  • Advertise or offer to sell or buy any goods or services for
                  any business purpose, unless such Communication Service
                  specifically allows such messages.{" "}
                </p>
              </li>
              <li className="mb-3">
                <p>
                  • Conduct or forward surveys, contests, pyramid schemes or
                  chain letters{" "}
                </p>
              </li>
              <li className="mb-3">
                <p>
                  • Download any file posted by another user of a Communication
                  Service that you know, or reasonably should know, cannot be
                  legally distributed in such manner.
                </p>
              </li>
            </ul>
            <p className="mb-4">
              This Practice has no obligation to monitor the Communication
              Services. However,this Practice reserves the right to review
              materials posted to a Communication Service and to remove any
              materials in its sole discretion.This Practice reserves the right
              to terminate your access to any or all of the Communication
              Services at any time without notice for any reason whatsoever.
            </p>
            <p className="mb-4">
              Materials uploaded to a Communication Service may be subject to
              posted limitations on usage, reproduction and/or dissemination.You
              are responsible for adhering to such limitations if you download
              the materials.
            </p>
          </div>
          <div className="mb-3">
            <h2 className="mb-3 h4 mt-5">Liability disclaimer</h2>
            <p className="mb-4">
              The information,software,products,and services included in or
              available through this practice web site may include inaccuracies
              or typographical errors.Changes are periodically added to the
              information herein.This practice and/or its suppliers may make
              improvements and/or changes in this practice web site at any
              time.Advice received via this practice web site should not be
              relied upon for personal, medical, legal or financial decisions
              and you should consult an appropriate professional for specific
              advice tailored to your situation.
            </p>
            <p className="mb-4">
              This practice and/or its suppliers make no representations about
              the suitability, reliability,availability,timeliness,and accuracy
              of the information,software,products, services and related
              graphics contained on this practice web site for any purpose.To
              the maximum extent permitted by applicable law,all such
              information, software, products, services and related graphics are
              provided'As is"without warranty or condition of any kind.This
              practice and/or its suppliers hereby disclaim all warranties and
              conditions with regard to this information,software, products,
              services and related graphics, including all implied warranties or
              conditions of merchantability,fitness for a particular purpose,
              title and non-infringement.
            </p>
            <p className="mb-4">
              To the maximum extent permitted by applicable law, in no event
              shall this practice and/or its suppliers be liable for any direct,
              indirect,punitive, incidental,special,consequential damages or any
              damages whatsoever including, without limitation,damages for loss
              of use,data or profits,arising out of or in any way connected with
              the use or performance of this practice web site, with the delay
              or inability to use this practice web site or related services,the
              provision of or failure to provide services,or for any
              information, software, products,services and related graphics
              obtained through this practice web site, or otherwise arising out
              of the use of this practice web site, whether based on contract,
              tort, negligence,strict liaability or otherwise,even if this
              practice or any of its suppliers has been advised of the
              possibility of damages. Because some states/jurisdictions do not
              allow the exclusion or limitation of liability for consequential
              or incidental damages,the above limitation may not apply to you.
              If you are dissatisfied with any portion of this practice web
              site, or with any of these terms of use,your sole and exclusive
              remedy is to discontinue using this practice web site.
            </p>
          </div>
          <div className="mb-3">
            <h2 className="mb-3 h4 mt-5">Copyright and trademark notices:</h2>
            <p>
              All contents of this website are Copyright © 2022 PatientPop
              Inc.All Rights Reserved.All logos are trademarks and service marks
              of PatientPop Inc.All other trademarks,service marks and logos
              used in this website are the property of their respective owners.
            </p>
          </div>
          <div className="mb-3">
            <h2 className="mb-3 h4 mt-5">Trademarks</h2>
            <div>
              <p className="mb-4">
                The names of actual companies and products mentioned herein may
                be the trademarks of their respective owners.
              </p>
              <p className="mb-4">
                The example companies,organizations, products, people and events
                depicted herein are fictitious. No association with any real
                company,organization,product,person,or event is intended or
                should be inferred.
              </p>
              <p className="mb-3">
                Any Rights not Expressly granted herein are reserved.
              </p>
            </div>
          </div>
          <div className="mb-3">
            <h2 className="mb-3 h4 mt-5">Notices</h2>
            <div>
              <p className="mb-4">
                Please contact us by phone at{" "}
                <a href="#0" className="text-primary">
                  <u>254-218-6349</u>
                </a>{" "}
                or by mail at 3300 East Central Texas Express Way, Suite 302,
                Killeen,TX 76543.
              </p>
            </div>
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
