import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Img1 from "../images/team-01.png";
import Close from "../images/Close.svg";
import Img2 from "../images/team-02.png";
import Img3 from "../images/team-03.png";

function Team() {
  const [isShown, setIsShown] = React.useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  const [isShown1, setIsShown1] = React.useState(false);
  const handleClick1 = () => {
    setIsShown1((current) => !current);
  };
  const [isShown2, setIsShown2] = React.useState(false);
  const handleClick2 = () => {
    setIsShown2((current) => !current);
  };
  return (
    <section id="our-team">
      <div className="max-w-6xl max-w-8xl mx-auto px-4 sm:px-6 mt-5">
        <div className="pt-10 pb-12 md:pt-16 md:pb-20">
          <div data-aos="fade-up" className="text-center">
            <h4>Meet the team</h4>
            <h2 className="h2 mt-3 mb-5">Our dentists team</h2>
          </div>

          <div className="row mb-5">
            <div
              onClick={handleClick}
              data-aos="fade-right"
              data-aos-delay="200"
              className="col-lg-4 col-md-4 col-sm-12"
            >
              <img className="teamImgClass" src={Img1} alt="Killeen Family Dentistry Team" />
              <div className="px-3 pt-3 mob-team">
                <h5 className="h5">Andy Yassa, DDS</h5>
                <p className="text-primary fontSize-14">Dentist</p>
              </div>
            </div>
            <Dialog
              maxWidth="lg"
              fullWidth={true}
              open={isShown}
              onClose={handleClick}
            >
              <DialogContent>
                <div className="row">
                  <img onClick={handleClick} className="close-btn" src={Close} alt="close" />
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <img
                      className="teamImgClass"
                      src={Img1}
                      alt="Killeen Family Dentistry Team"
                    />
                  </div>
                  <div className="col-lg-8 mt-3 lg-p-3">
                    <h3 className="pop-h">Dr. Yassa</h3>
                    <p>
                      Dr. Andy Yassa provides exceptional dental care at Killeen
                      Family Dentistry. Patients in Killeen, Texas, can trust
                      Dr. Yassa to treat them with a caring attitude while
                      providing gentle care. Whether pediatric patients need
                      their teeth cleaned or adult patients need a dentures, 
                      they’ll receive quality care in a welcoming environment.
                    </p>

                    <p className="mt-2">
                      Dr. Yassa graduated from UT Health Science Center at
                      Houston in 2003, earning a Doctor of Dental Surgery
                      degree. He went into practice right after graduation and
                      has been providing dental care for patients of all ages
                      since then. He ensures that patients both young and old
                      get the care they need while also having a relaxing and
                      comfortable visit.
                    </p>

                    <p className="mt-2">
                      Dr. Yassa is skilled at treating adults and children
                      alike, but he loves the rewarding experience that comes
                      with working with pediatric patients. His goal is to help
                      each child have a stress-free experience while also
                      learning valuable information about keeping their teeth
                      and gums healthy. Dr. Yassa’s commitment to making sure
                      that young patients are comfortable during their visits
                      makes dental work a fun and easy experience for them.
                    </p>

                    <p className="mt-2">
                      When Dr. Yassa isn’t providing dental care at Killeen
                      Family Dentistry, he loves spending time with his wife,
                      Dr. Michelle West, and their three sons Ethan, Benjamin,
                      and William.
                    </p>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <div
              onClick={handleClick1}
              data-aos="fade-up"
              data-aos-delay="300"
              className="col-lg-4 col-md-4 col-sm-12"
            >
              <img className="teamImgClass" src={Img2} alt="Killeen Family Dentistry Team" />
              <div className="px-3 pt-3 mob-team">
                <h5 className="h5">Jason Costa, DDS</h5>
                <p className="text-primary fontSize-14">Dentist</p>
              </div>
            </div>
            <Dialog
              maxWidth="lg"
              fullWidth={true}
              open={isShown1}
              onClose={handleClick1}
            >
              <DialogContent>
                <div className="row">
                  <img onClick={handleClick1} className="close-btn" src={Close} alt="close" />
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <img
                      className="teamImgClass"
                      src={Img2}
                      alt="Killeen Family Dentistry Team"
                    />
                  </div>
                  <div className="col-lg-8 mt-3 lg-p-3">
                    <h3 className="pop-h">Dr. Costa</h3>
                    <p>
                      Dr. Jason Costa treats patients of all ages at Killeen
                      Family Dentistry, located in Killeen, Texas. Dr. Costa
                      provides superior dental care for children and adults,
                      whether they need routine cleanings, crowns, or cosmetic
                      services. He stays up-to-date on the latest advancements
                      in dental care to provide outstanding dental services.
                    </p>

                    <p className="mt-2">
                      After growing up in Central Texas, Dr. Costa earned a
                      degree in chemical engineering at the University of Texas.
                      When he decided to pursue dentistry as a career instead,
                      he earned his dentistry degree from the Baylor College of
                      Dentistry. Dr. Costa has been providing dental care for
                      patients in the Central Texas area since 2007 and
                      continues to enjoy helping patients achieve good oral
                      health.
                    </p>

                    <p className="mt-2">
                      Dr. Costa offers gentle dental care in a calming
                      environment that helps all of his patients feel at ease
                      while they receive treatment. He enjoys ensuring that
                      patients have healthy teeth and gums while providing them
                      with advice on maintaining good oral health.
                    </p>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <div
              onClick={handleClick2}
              data-aos="fade-left"
              data-aos-delay="400"
              className="col-lg-4 col-md-4 col-sm-12"
            >
              <img className="teamImgClass" src={Img3} alt="Killeen Family Dentistry Team" />
              <div className="px-3 pt-3 mob-team">
                <h5 className="h5">Lyna Vuu, DDS</h5>
                <p className="text-primary fontSize-14">Dentist</p>
              </div>
            </div>
            <Dialog
              maxWidth="lg"
              fullWidth={true}
              open={isShown2}
              onClose={handleClick2}
            >
              <DialogContent>
                <div className="row">
                  <img onClick={handleClick2} className="close-btn" src={Close} alt="close" />
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <img
                      className="teamImgClass"
                      src={Img3}
                      alt="Killeen Family Dentistry Team"
                    />
                  </div>
                  <div className="col-lg-8 mt-3 lg-p-3">
                    <h3 className="pop-h">Dr. Vuu</h3>
                    <p>
                      Dr. Lyna Vuu was raised in Sugarland, Texas. She graduated
                      from Texas A&M with a bachelor’s in biomedical science and
                      went on to the University of Texas, San Antonio where she
                      graduated with her Doctor of Dental Surgery. She has a
                      passion for working with children of all ages and enjoys
                      creating a fun and safe experience for them. During her
                      free time, Dr. Vuu loves reading, cooking, and attending
                      music festivals.
                    </p>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
