import React from "react";
import { useLocation } from "react-router-dom";

export default function OtherHeader() {
  const location = useLocation();
  return (
    <section className="relative">
      {/* Dark background */}
      <div
        className="absolute inset-0 bg-color bg-slate-900 pointer-events-none -z-10 [clip-path:polygon(0_0,_5760px_0,_5760px_calc(100%_-_352px),_0_100%)]"
        aria-hidden="true"
      ></div>

      <div className="relative max-w-6xl max-w-8xl mx-auto px-4">
        <div className="pt-32 pb-20 pt-40 pb-44">
          {/* Hero content */}
          <div className="mx-auto max-w-none flex items-center space-x-8 lg:space-x-16 xl:space-x-20 space-y-16 space-y-0 justify-content-center">
            {/* Content */}
            {location.pathname === "/terms-condition" ? (
              <h1 data-aos="zoom-in-up" data-aos-delay="100" className="other-header-display mb-4">
                {" "}
                Terms & Conditions{" "}
              </h1>
            ) : (
              ""
            )}
            {location.pathname === "/privacy-policy" ? (
              <h1 data-aos="zoom-in-up" data-aos-delay="100" className="other-header-display mb-4"> Privacy Policy</h1>
            ) : (
              ""
            )}
            {location.pathname === "/accessibilty-statement" ? (
              <h1 data-aos="zoom-in-up" data-aos-delay="100" className="other-header-display mb-4">
                {" "}
                Accessibilty Statement{" "}
              </h1>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
