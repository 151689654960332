import React from "react";
import Img1 from "../images/process-Icon.png";
import Img2 from "../images/p2.png";
import Img3 from "../images/p3.png";
import Img4 from "../images/Signup.svg";
import Img5 from "../images/Downloads.png";
import Img6 from "../images/Icon.png";

function Process() {
  return (
    <section id="process" className="pt-5 process_container">
      <div className="max-w-6xl max-w-8xl mx-auto px-4 sm:px-6 pb-5">
        <img className="p1-img" src={Img3} alt="Killeen Family Dentistry" />
        <img className="p2-img" src={Img2} alt="Killeen Family Dentistry" />
        <div className="pt-10 pb-12 md:pt-16 md:pb-20 pt-5">
          {/* Section header */}
          <div
            className="max-w-3xl process-head mx-auto text-center md:pb-20 row justify-content-center"
            style={{ color: "black" }}
          >
            <img className="p-Icon" src={Img1} alt="Icon" />
            <h2 className="h2 mb-4 process-heading" data-aos="fade-up">
              No Dental Insurance,
              <br />
              No Problem.{" "}
            </h2>
            {/* <h2 className="h2 mb-4" data-aos="fade-up">Get dental plan in just 3 easysteps</h2> */}
            {/* <img onClick={useNavigate('/')} className=' process-cta-img cta-class' src={CTA} alt="" /> */}
          </div>
          <p className="justify-center text-center pb-12 d-flex">
            Comprehensive Dental Care for You and Your Family at a Discount
          </p>

          <div className="d-flex mob-process">
            {/* 1st item */}
            <div
              className="relative flex w-33percent flex-col items-center process-cont"
              data-aos="fade-up"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
                data-aos="fade-in"
                data-aos-delay="200"
              ></div>
              {/* <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <path className="stroke-current text-purple-300" strokeWidth="2" strokeLinecap="square" d="M21 23h22v18H21z" fill="none" fillRule="evenodd" />
                <path className="stroke-current text-purple-100" d="M26 28h12M26 32h12M26 36h5" strokeWidth="2" strokeLinecap="square" />
              </svg> */}
              <img className="process-icons" src={Img4} alt="Icon" />
              <h3 className="h4 mt-4 mb-2" style={{ color: "black" }}>
                1. Check your plan
              </h3>
              <p
                className="text-lg text-center"
                style={{ color: "rgba(29, 38, 58, 0.8)" }}
              >
                You'll get a detailed price list with the discounted rates on
                all the dental care services we offer.
              </p>
            </div>

            {/* 2nd item */}
            <div
              className="relative flex w-33percent flex-col items-center process-cont"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
                data-aos="fade-in"
                data-aos-delay="400"
              ></div>
              {/* <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <g fill="none" fillRule="evenodd">
                  <path className="stroke-current text-purple-300" d="M40 22a2 2 0 012 2v16a2 2 0 01-2 2H24a2 2 0 01-2-2V24a2 2 0 012-2" strokeWidth="2" strokeLinecap="square" />
                  <path className="stroke-current text-purple-100" strokeWidth="2" strokeLinecap="square" d="M36 32l-4-3-4 3V22h8z" />
                </g>
              </svg> */}
              <img className="process-icons" src={Img5} alt="Icon" />
              <h3 className="h4 mt-4 mb-2" style={{ color: "black" }}>
                2. Subscribe and pay
              </h3>
              <p
                className="text-lg text-center mx-5"
                style={{ color: "rgba(29, 38, 58, 0.8)" }}
              >
                You can subscribe and pay online, or in our office. It will just
                take a few minutes.
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex w-33percent flex-col items-center process-cont"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              {/* <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <path className="stroke-current text-purple-300" strokeWidth="2" strokeLinecap="square" d="M21 35l4 4 12-15" fill="none" fillRule="evenodd" />
                <path className="stroke-current text-purple-100" d="M42 29h-3M42 34h-7M42 39H31" strokeWidth="2" strokeLinecap="square" />
              </svg> */}
              <img className="process-icons" src={Img6} alt="Icon" />
              <h3 className="h4 mt-4 mb-2" style={{ color: "black" }}>
                3. Get dental care
              </h3>
              <p
                className="text-lg  text-center"
                style={{ color: "rgba(29, 38, 58, 0.8)" }}
              >
                Schedule appointments, receive dental care, and enjoy discounted
                rates.
              </p>
            </div>
          </div>
          <div className="d-flex mt-3rem">
            <a
              href="https://killeendentistry.subscribili.com/"
              target="_blank"
              rel="noreferrer"
              className="btn-enroll mx-auto text-white bg-blue ml-6"
            >
              Enroll Now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
