import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/footer-logo.png";

function Footer() {
  return (
    <footer className="footer-10 footerClass">
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-4">
            <h2 className="footer-heading d-flex">
              <img className="" src={Logo} alt="Logo" />
            </h2>
            <ul className="footer-p">
              Offer a wide range of dental services to help patients keep their
              teeth and gums in excellent shape.{" "}
            </ul>
          </div>
          <div className="col-md-3 mb-4">
            <h2 className="footer-heading">Contact Us</h2>
            <ul className="list-unstyled footer-p">
              <li>Killeen Family Dentistry</li>
              <li className="mt-1">
                3300 East Central Texas Express Way, Suite 302
              </li>
              <li className="mt-2">Killeen, TX 76543</li>
              <li>Phone: 254-218-6349</li>
              <li>Fax: 254-699-3465</li>
            </ul>
          </div>
          <div className="col-md-3  mb-4">
            <h2 className="footer-heading">Legal & Help</h2>
            <ul className="list-unstyled footer-p">
              <li>
                <Link to="/privacy-policy" className="mt-1 d-block">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-condition" className="mt-1 d-block">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="/accessibilty-statement" className="mt-1 d-block">
                  Accessbility Notice
                </Link>
              </li>
              <li>
                <Link to="/contact" className="mt-1 d-block">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3  mb-4">
            <h2 className="footer-heading">Office Hours</h2>
            <ul className="list-unstyled footer-p">
              <li className="justify-content-between d-flex text-center">
                <span>Monday : </span>8:30 am - 4:30 pm
              </li>
              <li className="justify-content-between d-flex">
                <span>Tuesday :</span> 8:30 am - 4:30 pm{" "}
              </li>
              <li className="justify-content-between d-flex">
                <span>Wednesday :</span> 7:30 am - 3:30 pm{" "}
              </li>
              <li className="justify-content-between d-flex">
                <span>Thursday :</span> 8:30 am - 4:30 pm{" "}
              </li>
              <li className="justify-content-between d-flex">
                <span>Friday :</span> 7:30 am - 3:30 pm{" "}
              </li>
              <li className="justify-content-between d-flex">
                <span>Saturday :</span>
                <span className="mr-5rem"> Closed</span>{" "}
              </li>
              <li className="justify-content-between d-flex">
                <span>Sunday :</span>
                <span className="mr-5rem"> Closed</span>
              </li>
            </ul>
          </div>
          {/* <div className="col-md-5 mb-md-0 mb-4">
                        <h2 className="footer-heading">Subscribe</h2>


                    </div> */}
        </div>
        <div className="row mt-5 pt-4 border-top font-14">
          <div className="col-md-8 col-lg-9 mb-4">
            <div className="row justify-content-start w-50 m-w-80 text-white">
              <Link to="/contact" className="col-lg-3 col-md-3">
                Contact
              </Link>
              <Link to="/about" className="col-lg-3 col-md-3">
                About us
              </Link>
              <Link to="/" className="col-lg-3 col-md-3">
                FAQ's
              </Link>
              <Link to="/" className="col-lg-3 col-md-3">
                Support
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-lg-3 mb-4 d-flex">
            <p className="copyright mb-0 text-white ml-auto">
              © 2022, All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
<footer className="footer-10"></footer>;
