import React from "react";
import Img1 from "../images/say-01.jpg";
import Img2 from "../images/say-02.jpg";
import Img3 from "../images/say-03.jpg";
import backG from "../images/say-backG.svg";
import star from "../images/star 1 @3x.png";

function WhatPeopleSay() {
  return (
    <section id="what-people-say" className="say_container">
      <div data-aos="fade-up" data-aos-delay="400" className="max-w-6xl max-w-8xl mx-auto px-4 sm:px-6 mt-5">
        <div className="row justify-content-center">
          <div className="text-center">
            <h2 className="h2 mb-4">What people say</h2>
            <p className="mx-5 w-75 inline-block justify-content-center mb-5 say-subPara">
              30, 5-star reviews in Austin, TX and counting...
            </p>
          </div>
        </div>
      </div>
      <img className="say-backG" src={backG} alt="" />
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div data-aos="fade-right" data-aos-delay="400" className="m-mt-4rem bg-white col-lg-3 col-md-3 col-sm-12 shadow mr-5">
            <div className="testimonial-item-header">
              <div className="testimonial-item-image">
                <img
                  className="card_corner_image"
                  src={Img1}
                  alt="Testimonial 01"
                  width={56}
                  height={56}
                />
              </div>
              <div className="px-2 py-2">
                <p className="mb-3 card-p">
                  — Always an amazing visit!! Best staff around! Very
                  professional!! Only use Dr. Costa and today got to meet
                  TiErica and she was amazing as well!!!!
                </p>
              </div>
              <hr></hr>
              <div className="testimonial-item-footer my-3">
                <span className="testimonial-item-name text-color-high"></span>
                <div className="d-flex h-20px">
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                </div>
                <span className="text-color-low">4.9/5 - </span>
                <span className="text-primary">
                  <a href="#0">Brandon M.</a>
                </span>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="500" className="m-mt-4rem bg-white col-lg-3 col-md-3 col-sm-12 shadow mr-5">
            <div className="testimonial-item-header">
              <div className="testimonial-item-image">
                <img
                  className="card_corner_image"
                  src={Img2}
                  alt="Testimonial 01"
                  width={56}
                  height={56}
                />
              </div>
              <div className="px-2 py-2">
                <p className="mb-3 card-p">
                  — Saw a different dentist outside of my regular dentist and he
                  was just as professional as Ms. Vuu thank you for your kind
                  and caring services
                </p>
              </div>
              <hr></hr>
              <div className="testimonial-item-footer my-3">
                {/* <span className='say-footer-svg'></span> */}
                <span className="testimonial-item-name text-color-high"></span>
                <div className="d-flex h-20px">
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                </div>
                <span className="text-color-low">4.9/5 - </span>
                <span className="text-primary">
                  <a href="#0">Olethia N.</a>
                </span>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" data-aos-delay="600" className="m-mt-4rem bg-white col-lg-3 col-md-3 col-sm-12 shadow">
            <div className="testimonial-item-header">
              <div className="testimonial-item-image">
                <img
                  className="card_corner_image"
                  src={Img3}
                  alt="Testimonial 01"
                  width={56}
                  height={56}
                />
              </div>
              <div className="px-2 py-2">
                <p className="mb-3 card-p">
                  — Outstanding in every way from the staff, dentist and
                  procedure performed. I will definitely be a returning
                  customer. Thank you so very much.
                </p>
              </div>
              <hr></hr>
              <div className="testimonial-item-footer my-3">
                <span className="testimonial-item-name text-color-high"></span>
                <div className="d-flex h-20px">
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                  <img src={star} alt="Killeen Family Dentistry Rating" />
                </div>
                <span className="text-color-low">4.9/5 - </span>
                <span className="text-primary">
                  <a href="#0">Paul E.</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhatPeopleSay;
