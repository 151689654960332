import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";
const data = [
  {
    key: 1,
    title: "What is a dental sealant?",
    content:<div className="services-text">
    <p>A dental sealant is a thin coating of plastic applied to rough areas on the back teeth to protect them.</p>
    <p>The chewing surface of the molars is at risk for decay in children and teens because they're hard to clean. Bacteria produce acid that creates holes in teeth. The bacteria travel around the mouth in food particles.</p>
    <p>A sealant works a lot like a raincoat. By putting sealants on back molars, the plastic keeps the food from settling into the teeth where the acid can make a hole.</p>
    <p>A sealant can be placed over a cavity to prevent further decay, too.</p>
  </div>
  },
  {
    key: 2,
    title: "Is sealant only applied to back teeth?",
    content:<div className="services-text">
    <p>Killeen Family Dentistry only uses sealants for permanent molars.</p>
    <p>The first ones come in sometime around age 6 with a second set of molars appearing around age 12. One application lasts for five to 10 years but can help keep these crucial teeth healthy throughout the child’s life by protecting them when they are most vulnerable.</p>
  </div>
  },
  {
    key: 3,
    title: "Are sealants only for kids?",
    content:<div className="services-text">
    <p>The earlier sealants are in place, the better, which is why family dentists like those at the Killeen Family Dentistry usually recommend them for their young patients. However, they sometimes give sealants to young adults if they missed out on them when the molars first appeared.</p>
  </div>
  },
  {
    key: 4,
    title: "How is the sealant applied?",
    content:<div className="services-text">
    <p>The application process is quick and easy. One of the Killeen Family Dentistry team members cleans and dries the target tooth first.</p>
    <p>Next, they apply an acidic gel to the tooth to roughen up the surface. Creating texture on the enamel gives the sealant something to grip once in place.</p>
    <p>The next step is to paint the sealant into the grooves of the tooth and cure it with a blue light to harden the coating.</p>
    <p>Once complete, the plastic might dry clear, white or slightly tinted. The sealant is very thin, so even though a child may feel it with his or her tongue, after awhile, it will be barely noticeable.</p>
    <p>To learn more about how Killeen Family Dentistry can protect your child’s teeth, call the office to book an appointment today.</p>
  </div>
  }
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-2">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && <><p data-aos="fade-up" className="accordion-content pt-4 pb-1">{content}</p></>}
      </div>
    </div>
  );
};

export default function Sealants() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
            The professionals at Killeen Family Dentistry in Killeen, Texas believe being proactive from a young age is the best way to care for teeth and gums. Dental sealants add one more layer of protection to young teeth by preventing cavities from forming on difficult-to-clean molars. If you're wondering whether a dental sealant is an excellent choice for your kid’s teeth, give the office a call to find out more about how they work.
            </p>
          </div>
          <div data-aos="fade-left " data-aos-delay="200" className="mt-2rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
