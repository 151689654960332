import React from "react";
import HeroHome from "../partials/HeroHome";
import Header from "../partials/Header";
import Process from "../partials/Process";
import Footer from "../partials/Footer";
import Advance1 from "../partials/Advance1";
import Team from "../partials/Team";
import WhatPeopleSay from "../partials/WhatPeopleSay";
import HomeService from "../partials/Services";
import Connect from "../partials/Connect";

export default function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<HeroHome />}
      <main className="grow">
        {/*  Page sections */}
        {<Connect />}
        {<HomeService />}
        {<Process />}
        {<Advance1 />}
        {<Team />}
        {<WhatPeopleSay />}
      </main>
      {<Footer />}
    </div>
  );
}
