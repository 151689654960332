import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";
const data = [
  {
    key: 1,
    title: "What is a dental exam?",
    content:<div className="services-text">
    <p>A dental exam allows the dentist or hygienist at Killeen Family Dentistry to get a comprehensive look at the teeth of the patient.</p>
    <p>During an exam, the staff takes a full medical history to determine if any conditions might affect the health of the teeth or gums. They also check for cavities and any signs of gum disease.</p>
    <p>With a full exam, the dentist sees how well kids (or adults) are cleaning their teeth and instructs them in a way that leads to a lifetime of good oral hygiene and habits.</p>
    <p>One of the Killeen Family Dentistry professionals may order an X-ray to see how the adult teeth are coming or to gauge the alignment. The staff also use this time to talk about things like diet and brushing to prevent problems down the road too. Lifestyle changes can improve oral health significantly, for example.</p>
    </div>
  },
  {
    key: 2,
    title: "What is a dental cleaning?",
    content:<div className="services-text">
    <p>The staff at Killeen Family Dentistry recommends that all patients of all ages get their teeth professionally cleaned once every six months. A professional cleaning reinforces home dental care and gives the dentist a chance to look at the teeth to find potential problems.</p>      
    <p>A professional dental cleaning has several goals, including:</p>
    <ul className="disc"><li>Removal of plaque and tartar</li><li>Removal of stains</li><li>To do a fluoride application</li><li>To polish the teeth</li></ul>
    <p>For adults, the cleaning might also include the use of sealants and the cleaning and adjustment of dentures or bridges.</p>
    </div>
  },
  {
    key: 3,
    title: "What to expect from a dental exam?",
    content:<div className="services-text">
    <p>During a dental exam, the dentist will:</p>
    <ul className="disc"><li>Clean the teeth</li><li>Analyze the health of the teeth and gums</li><li>Evaluate the patient's risk of dental problems like decay</li><li>Assess the need for more advanced procedures like tooth replacement or adjustments</li><li>Demonstrate proper brushing techniques, especially to kids</li></ul>
    <p>The exam might also include diagnostic tests to rule out dangerous conditions like oral cancer and periodontal disease. In some cases, a dental impression of one or both jaws helps the staff at Killeen Family Dentistry evaluate the bite of a child and create any necessary appliances, like a mouth guard or bleaching tray.</p>
    </div>
  }
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-2">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && <p data-aos="fade-up" className="accordion-content pt-4 pb-1">{content}</p>}
      </div>
    </div>
  );
};

export default function DentalCleaningExam() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
            Preventive dental care starts with a comprehensive exam and cleaning. The dental professionals at Killeen Family Dentistry in Killeen, Texas believe in working with their patients to create a dental strategy that includes steps to keep the teeth and supporting tissue healthy. A dental cleaning and regular exam is the key. If you want your teeth cleaned, click or call to make an appointment at Killeen Family Dentistry.
            </p>
          </div>
          <div className="mt-10rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
