import Header from "../partials/Header";
import Footer from "../partials/Footer";
import HeroHome from "../partials/HeroHome";
import SubBenefits from "../partials/SubBenefits";

export default function Subscribe() {
  return (
    <div>
      {<Header />}
      {<HeroHome />}
      <main className="grow">
        {/*  Page sections */}
        {<SubBenefits />}
      </main>
      {<Footer />}
    </div>
  );
}
