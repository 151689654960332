import React from "react";
import IMG1 from "../images/periodontics.png";
import IMG2 from "../images/fillings.png";
import IMG3 from "../images/sedation1.jpg";
import IMG4 from "../images/dentures.png";
import IMG5 from "../images/briges.png";
import IMG6 from "../images/placeholder.png";
import IMG7 from "../images/crowns.png";
import IMG8 from "../images/extractions.png";
import IMG9 from "../images/Filling@2x.jpg";
import IMG10 from "../images/sealants1.jpg";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import HeroHome from "../partials/HeroHome";
import CTA from "../images/CTA.svg";
import { Link } from "react-router-dom";


const data = [
  {
    key: 1,
    image: IMG1,
    title: "Pediatric Dentistry Specialist",
    url: '/services/pediatric-dentistry',
    content:
      "Routine dental checkups are essential for keeping your children's teeth and gums healthy and lowering the risk of cavities and other problems. Even if your kids only have baby teeth, they still need to have their teeth examined to make sure they’re in good shape. The dental team at Killeen Family Dentistry provides high-quality, caring pediatric dentistry services for kids in Killeen, Texas.",
  },
  {
    key: 2,
    image: IMG2,
    title: "Crowns And Bridges Specialist",
    url: '/services/crowns-and-bridges',
    content: "Having damaged or missing teeth can have a substantial impact on the way you feel about your smile. They can also put you at risk of infections since these areas of your mouth are vulnerable to bacteria. Dental crowns and bridges provide you with a way to protect these areas while also improving your smile. At Killeen Family Dentistry, the dental team offers crowns and bridges to patients in Killeen, Texas, who have missing or damaged teeth.",
  },
  {
    key: 3,
    image: IMG3,
    title: "Sedation Dentistry Specialist",
    url: '/services/sedation-dentistry',
    content: "Sometimes, getting kids and even some adults to the dentist is tricky. At Killeen Family Dentistry in Killeen, Texas, patients who are nervous about seeing a dentist have the option of sedation. Sedation dentistry means each visit can be relaxing instead of taxing. It’s a dental service that caters to those that might otherwise avoid coming in or might be traumatized from an office visit. If you or a little one is worried about getting a dental procedure, don’t be: Call the office of Killeen Family Dentistry and ask about sedation options.",
  },
  {
    key: 4,
    image: IMG4,
    title: "Dentures Specialist",
    url: '/services/dentures',
    content:
      "Having several missing teeth makes it hard to do simple things like speaking clearly and chewing, which can affect the quality of your life. You may also lose confidence in your appearance when you have missing teeth in the front of your mouth. Killeen Family Dentistry, located in Killeen, Texas, provides patients with full or partial dentures to help restore tooth function and improve their appearance.",
  },
  {
    key: 5,
    image: IMG5,
    title: "Emergency Dentistry Specialist",
    url: '/services/emergency-dentistry',
    content:
      "Sometimes, a person can’t afford to wait for days to see the dentist. That's why Killeen Family Dentistry in Killeen, Texas proudly offers families emergency dentistry because some things can’t wait. Through their emergency dentistry service, patients can call the office and know they will be seen right away if necessary. Emergency dentistry is a 24/7, 365-days-a-year service. If you or a child in your care has a dental emergency, call the Killeen Family Dentistry office for assistance.",
  },
  {
    key: 6,
    image: IMG6,
    title: "Family Dentistry Specialist",
    url: '/services/family-dentistry',
    content:
      "A family dentistry practice like Killeen Family Dentistry in Killeen, Texas provides general oral care for every member of the house at every stage of their life. Parents with children need to look no further for dental care for themselves and the kids. That’s important because the whole family can come in and get a checkup in one trip. If you are looking for a dentist to serve everyone in the house, give the office at Killeen Family Dentistry a call.",
  },
  {
    key: 7,
    image: IMG7,
    title: "Fillings Specialist",
    url: '/services/fillings',
    content:
      "When you have a hole in your tooth due to decay, bacteria can enter and lead to an infection. Filling these holes helps prevent this from happening and keeps your tooth more intact. Fillings are among the most common types of dental care. At Killeen Family Dentistry, located in Killeen, Texas, the dental team may recommend fillings for patients both young and old who have tooth decay.",
  },
  {
    key: 8,
    image: IMG8,
    title: "Tooth Extractions Specialist",
    url: '/services/tooth-extractions',
    content:"Nobody likes getting a tooth pulled, but sometimes it is necessary. The dental professionals at Killeen Family Dentistry in Killeen, Texas work with patients, so they understand how the procedure and why they need it. Patient education is an integral part of managing anxiety, but the office also offers sedation options for the patient to help put them at ease. If you or a member of your family needs a tooth pulled, click or call for more information and a consultation.",
  },
  {
    key: 9,
    image: IMG9,
    title: "Dental Cleaning And Exam Specialist",
    url: '/services/dental-cleaning-and-exam',
    content:"Preventive dental care starts with a comprehensive exam and cleaning. The dental professionals at Killeen Family Dentistry in Killeen, Texas believe in working with their patients to create a dental strategy that includes steps to keep the teeth and supporting tissue healthy. A dental cleaning and regular exam is the key. If you want your teeth cleaned, click or call to make an appointment at Killeen Family Dentistry.",
  },
  {
    key: 10,
    image: IMG10,
    title: "Sealants Specialist",
    url: '/services/sealants',
    content:"The professionals at Killeen Family Dentistry in Killeen, Texas believe being proactive from a young age is the best way to care for teeth and gums. Dental sealants add one more layer of protection to young teeth by preventing cavities from forming on difficult-to-clean molars. If you're wondering whether a dental sealant is an excellent choice for your kid’s teeth, give the office a call to find out more about how they work.",
  },
];

export default function Services() {
  return (
    <div>
      {<Header />}
      {<HeroHome />}
      <section className="services_section container">
        <div className="container">
          {data.map(({ key, image, title, url, content }) => {
            return (
              <div key={key} data-aos="fade-up" data-aos-delay="100" className="row justify-content-between mt-5">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <img src={image} alt={title} className="imageClass" />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 mt-4">
                  <h2 className="headings-2">{title}</h2>
                  <p>{content}</p>
                  <div className="mt-4">
                    <Link to={url}>
                    <img className="cta-class" src={CTA} alt="Killeen Family Dentistry Learn More" />
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {<Footer />}
    </div>
  );
}
