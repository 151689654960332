import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ContactHead from "../partials/ContactHead";
import Phone from "../images/call_talking.svg";
import Email from "../images/Email.svg";
import Address from "../images/location.svg";

const Contact = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ContactHead />}
      <main className="grow space-contact">
        {/*  Page sections */}
        <div className="mt-10rem">
          <div className="container d-flex justify-content-center">
            <div className="md-hidden w-30vw">
              <div className="row contact-bg px-5 py-5">
                <div>
                  <div className="d-flex">
                    <img className="mr-2" src={Address} alt="Killeen Family Dentistry Address" /> <b>Address</b>
                  </div>
                  <div className="mt-3 mb-4 d-flex flex-column">
                    <span>3300 E Central Texas Expy #302,</span>
                    <span>Killeen,TX 76543</span>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <img className="mr-2" src={Phone} alt="Killeen Family Dentistry Phone" />
                    <b> Contact Us</b>{" "}
                  </div>
                  <div className="mt-3 mb-4 d-flex flex-column">
                    <span>Phone (appointments): 254-218-6349</span>
                    <span>Phone (general inquiries): 254-699-6799</span>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <img className="mr-2" src={Email} alt="Killeen Family Dentistry Email" /> <b>Email Us</b>
                  </div>
                  <span className="mt-3 mb-4 d-flex flex-column">
                    yaschira@killeenfamilydentistry.com
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
};

export default Contact;
