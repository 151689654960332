import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";
const data = [
  {
    key: 1,
    title: "What is family dentistry?",
    content:<div className="services-text">
    <p>Family dentistry is a specialty in the general dentistry category. A general dentist can treat conditions that affect the teeth and supporting tissue at any stage of life.</p>
    <p>Family dentists like those at Killeen Family Dentistry work to build relationships with the families that come to the office. Children do better when they come to one office for all their dental needs because they build trust with the staff there.</p>
    <p>A good family dentist treats each stage of the patient's dental life, baby teeth to adult teeth. The service includes anything a general dentist does like:</p>
    <ul className="disc"><li>Regular cleanings</li><li>Fluoride treatments</li><li>Filling cavities</li><li>Orthodontics</li><li>Treatment for and the prevention of gum diseases</li></ul>
    <p>A family dentist is trained to work with children and to understand their special needs.</p>
    </div>
  },
  {
    key: 2,
    title: "Is a family dentist the same thing as a pediatric dentist?",
    content:<div className="services-text">
    <p>A pediatric dentist only treats children. A family dentist, like those at Killeen Family Dentistry, takes patients of all ages.</p>      
    <p>Mom can bring the little ones in for a cleaning and then stay to get her checkup too. Pediatric dentists stop treating a patient once they reach the age of 21.</p>
    </div>
  },
  {
    key: 3,
    title: "Who should see a family dentist?",
    content:<div className="services-text">
      <p>Anyone can come to the office for oral care, but the primary benefit of a family dental practice is that the whole family goes to one dental office.</p>
      <p>Parents can coordinate all trips to the dentist for the whole house at one time. With everyone in the family getting treatment under one roof, there is a higher level of trust and confidence too.</p>
      <p>Not all the patients that come to Killeen Family Dentistry have kids, but that doesn’t matter. The team treats individuals and families alike.</p>
    </div>
  },
  {
    key: 4,
    title: "Is there any treatment a family dentist can’t do?",
    content:<div className="services-text">
    <p>Family dentists might not do cosmetic procedures, for example, like a dental implant or bridge. They can send the patient to a specialist for something like that, but most other procedures occur right in the office.</p>
    <p>In that respect, a family dentist is similar to a primary care physician. Everything starts with the family dentist, and if the patient needs a specialty service, the Killeen Family Dentistry team can make a recommendation.</p>
    </div>
  } 
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-2">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && <p data-aos="fade-up" className="accordion-content pt-4 pb-1">{content}</p>}
      </div>
    </div>
  );
};

export default function FamilyDentistry() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
            A family dentistry practice like Killeen Family Dentistry in Killeen, Texas provides general oral care for every member of the house at every stage of their life. Parents with children need to look no further for dental care for themselves and the kids. That’s important because the whole family can come in and get a checkup in one trip. If you are looking for a dentist to serve everyone in the house, give the office at Killeen Family Dentistry a call.
            </p>
          </div>
          <div className="mt-2rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
