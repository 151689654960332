import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";
const data = [
  {
    key: 1,
    title: "What is sedation dentistry?",
    content:<div className="services-text">
    <p>Sedation dentistry involves using medication to relax a patient during their visit to Killeen Family Dentistry.</p>
    <p>Millions of people in this country suffer from dental anxiety, and it keeps them from getting the dental care they need. Sedation is a game-changer for them and for kids that are too scared to sit quietly in the chair.</p>
  </div>
  },
  {
    key: 2,
    title: "What is dental anxiety?",
    content:<div className="services-text">
    <p>Dental anxiety is the precursor to a problem that affects 9-20% of the adult population in this country: dental phobia. It’s a fear of going to the dentist that in many ways resembles post-traumatic stress disorder.</p>
    <p>Many kids have a difficult time at the dentist's office. Just the idea of going becomes very traumatic for them, especially because parents must force the issue.</p>
    <p>That fear carries into their adulthood when they are making their own decisions and becomes so overwhelming that they avoid regular checkups and procedures that can save their teeth.</p>
    <p>Killeen Family Dentistry believes that offering both young and old patients sedation is one way of overcoming this intense fear.</p>
  </div>
  },
  {
    key: 3,
    title: "How does sedation dentistry work?",
    content:<div className="services-text">
    <p>It varies from practice to practice, but typically it comes in three forms: gas, oral, and IV. Your dental professional will decide which one is right for each patient.</p>
    <h2>Gas sedation</h2>
    <p>Nitrous oxide, what most people know as laughing gas, is delivered via a nosepiece that the patient wears during the treatment. The effect of the laughing gas wears off very quickly.</p>
    <h2>Oral sedation</h2>
    <p>In this case, the patient takes a pill that makes them sleepy. Oral sedation is also called conscious sedation because it causes sleepiness, but the patient remains semi-awake. Once they awaken, they will have no memory of the dental procedure.</p>
    <h2>IV sedation</h2>
    <p>Most people associate IV sedation with surgery. General anesthesia is administered intravenously or through the veins. With IV sedation, the patient loses consciousness and wakes up with no memory of the event.</p>
    <p>IV sedation is reserved for the most serious scenarios like wisdom teeth extraction, for example. A medical professional at Killeen Family Dentistry will monitor the patient's vital signs during the sedation to ensure everything goes as planned.</p>
  </div>
  }
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-2">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && <><p data-aos="fade-up" className="accordion-content pt-4 pb-1">{content}</p></>}
      </div>
    </div>
  );
};

export default function SedationDentistry() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
            Sometimes, getting kids and even some adults to the dentist is tricky. At Killeen Family Dentistry in Killeen, Texas, patients who are nervous about seeing a dentist have the option of sedation. Sedation dentistry means each visit can be relaxing instead of taxing. It’s a dental service that caters to those that might otherwise avoid coming in or might be traumatized from an office visit. If you or a little one is worried about getting a dental procedure, don’t be: Call the office of Killeen Family Dentistry and ask about sedation options.
            </p>
          </div>
          <div data-aos="fade-left " data-aos-delay="200" className="mt-2rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
