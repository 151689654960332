import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "./App.css";
import "./css/style.css";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import "../node_modules/aos/dist/aos.js";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import TermCondition from "./pages/TermsCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AccessibilityStatement from "./pages/AccessibilityStatement ";
import AboutPage from "./pages/About";
import Fillings from "./pages/FIllings";
import SedationDentistry from "./pages/SedationDentistry";
import Dentures from "./pages/Dentures";
import EmergencyDentistry from "./pages/EmergencyDentistry";
import FamilyDentistry from "./pages/FamilyDentistry";
import DentalCleaningExam from "./pages/DentalCleaningExam";
import Sealants from "./pages/Sealants";
import CrownsBridges from "./pages/CrownsBridges";
import Extractions from "./pages/Extractions";
import PediatricDentistry from "./pages/pediatric-dentistry";
import Contact from "./pages/Contact";
import Subscribe from "./pages/Subscribe";
import PageNotFound from "./pages/PageNotFound";

function App() {
  useEffect(() => {
    AOS.init({
      once: false,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  });
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate replace to="/home" />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/services/pediatric-dentistry" element={<PediatricDentistry />} />
        <Route exact path="/services/crowns-and-bridges" element={<CrownsBridges />} />
        <Route exact path="/services/sedation-dentistry" element={<SedationDentistry />} />
        <Route exact path="/services/dentures" element={<Dentures />} />
        <Route exact path="/services/emergency-dentistry" element={<EmergencyDentistry />} />
        <Route exact path="/services/family-dentistry" element={<FamilyDentistry />} />
        <Route exact path="/services/fillings" element={<Fillings />} />
        <Route exact path="/services/tooth-extractions" element={<Extractions />} />
        <Route exact path="/services/dental-cleaning-and-exam" element={<DentalCleaningExam />} />
        <Route exact path="/services/sealants" element={<Sealants />} />        
        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/pricing" element={<Subscribe />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/terms-condition" element={<TermCondition />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          exact
          path="/accessibilty-statement"
          element={<AccessibilityStatement />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}
export default App;
