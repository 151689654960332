import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";
const data = [
  {
    key: 1,
    title: "What's the difference between pediatric dentistry and general dentistry?",
    content: <div className="services-text">
      <p>When you bring your kids to a pediatric dentist at Killeen Family Dentistry, they’ll receive care in a comfortable environment that helps them feel at ease.</p>
      <p>Pediatric dentists understand how nervous kids can be about dental visits and know how to help them feel relaxed. Your kids will also be seeing a dentist with skills and training in detecting dental problems that occur mainly in children's teeth, gums, and jaws as they’re developing.</p>
    </div>,
  },
  {
    key: 2,
    title: "How often do my kids need pediatric dentistry?",
    content: <div className="services-text">
      <p>For routine cleanings and exams, you should bring your kids in every six months. Doing so prevents plaque from building up on their teeth and increasing the risk of tooth decay.</p>
      <p>Coming in for checkups every six months also gives dentists a chance to determine if any problems require attention, such as issues with jaw development.</p>
    </div>
  },
  {
    key: 3,
    title: "What other services are part of pediatric dentistry?",
    content: <div className="services-text">
    <p>If the Killeen Family Dentistry team determines that your kids have cavities, problems with their bite, or other issues, they might need further services. Some of the pediatric dentistry services that are available include the following:</p>
    <p><ul className="disc"><li>Fillings for cavities</li><li>Tooth extractions for severe decay, injuries, or other impacted teeth</li><li>Athletic mouthguards and nightguards</li></ul></p>
  </div> 
  },
  {
    key: 4,
    title: "When should my kids start seeing a dentist?",
    content: <div className="services-text">
    <p>You should bring kids in for pediatric dental care at Killeen Family Dentistry by the time they reach their first birthday.</p>
    <p>Ideally, kids should start receiving dental care as their first teeth come in. That gives dentists a chance to check for potential problems with tooth or jaw development.</p>
  </div>
  },
  {
    key: 5,
    title: "What if my kids are nervous about coming in for dental care?",
    content: <div className="services-text">
    <p>At Killeen Family Dentistry, dental professionals and office staff treat all pediatric patients with kindness and understanding. They receive care in a warm and welcoming atmosphere that helps ease anxiety about dental visits.</p>
    <p>Kids are also encouraged to ask questions if they’re worried about what will happen during their visit. If your kids are due for checkups or if they need other dental care, please contact Killeen Family Dentistry to schedule an appointment.</p>
  </div>
  }
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-2">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && 
        <><p data-aos="fade-up" className="accordion-content  pt-4 pb-1">{content}</p></>
        }
      </div>
    </div>
  );
};

export default function PediatricDentistry() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
            Routine dental checkups are essential for keeping your children's teeth and gums healthy and lowering the risk of cavities and other problems. Even if your kids only have baby teeth, they still need to have their teeth examined to make sure they’re in good shape. The dental team at Killeen Family Dentistry provides high-quality, caring pediatric dentistry services for kids in Killeen, Texas.
            </p>
          </div>
          <div className="mt-2rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
