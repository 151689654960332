import React from "react";
import { Link } from "react-router-dom";
import Img2 from "../images/bitAboutPic.png";
import CTA from "../images/CTA.svg";

function Advance1() {
  return (
    <section
      id="advance1"
      className="section relative max-w-6xl max-w-8xl mx-auto px-4 py-5"
    >
      <div className="container">
        <div className="split-item my-5 py-5">
          <div
            className="split-item-content center-content-mobile reveal-from-right"
            data-reveal-container=".split-item"
          >
            <div className="d-flex mob-bit">
              <div data-aos="fade-down-right" data-aos-delay="50" className="w-50 margin-advance">
                <img className="bit-img" src={Img2} alt="" />
              </div>
              <div className="w-50">
                <h2 data-aos="fade-right" data-aos-delay="100" className="mt-0 h3 mb-5 bit-h3">A bit more about us</h2>
                <p className="text-gray-400 ml-5 bit-p">
                  Patients of all ages can receive high-quality dental care at
                  Killeen Family Dentistry, located in Killeen, Texas. The
                  dental team emphasizes compassionate and gentle care, which
                  helps children and adults alike feel more at ease about having
                  dental work done. Patients can count on being treated with
                  kindness while also receiving superior quality dental care.
                </p>
                <br />
                <p className="text-gray-400 ml-5">
                  The dentists at Killeen Family Dentistry offer a wide range of
                  dental services to help patients keep their teeth and gums in
                  excellent shape.
                  <br />
                  <Link to="/about" className="text-primary mt-5 absolute bit-a">
                    <img className="cta-class" src={CTA} alt="Killeen Family Dentistry" />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Advance1;
