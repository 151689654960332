import React from "react";
import { useLocation } from "react-router-dom";
import IMG1 from "../images/periodontics@2x.png";
import IMG2 from "../images/fillings@2x.png";
import IMG3 from "../images/sedation2.jpg";
import IMG4 from "../images/dentures@2x.png";
import IMG5 from "../images/briges@2x.png";
import IMG6 from "../images/placeholder@2x.png";
import IMG7 from "../images/crowns@2x.png";
import IMG8 from "../images/extractions@2x.png";
import IMG9 from "../images/Filling@2x.jpg";
import IMG10 from "../images/sealants2.jpg";


export default function ServiceHead() {
  const location = useLocation();
  return (
    <section className="relative h-44">
      {/* Dark background */}
      <div
        className="absolute inset-0 bg-color bg-slate-900 pointer-events-none -z-10 [clip-path:polygon(0_0,_5760px_0,_5760px_calc(100%_-_352px),_0_100%)]"
        aria-hidden="true"
      ></div>
      <div className="relative max-w-6xl max-w-8xl mx-auto px-4">
        <div className="pt-32 pb-20 pt-40 pb-44">
          <div className="mx-auto max-w-none flex items-center space-x-8 lg:space-x-16 xl:space-x-20 space-y-16 space-y-0 justify-content-center">
            <div className="pt-5 banner-section">
            {location.pathname === "/services/pediatric-dentistry" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center"> Pediatric Dentistry Specialist</h1>
                  <img src={IMG1} className="mt-5" alt="Pediatric Dentistry" />
                </div>
              ) : (
                ""
              )}
              {location.pathname === "/services/crowns-and-bridges" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center"> Crowns And Bridges Specialist</h1>
                  <img src={IMG2} className="mt-5" alt="Crowns And Bridges" />
                </div>
              ) : (
                ""
              )}
              {location.pathname === "/services/sedation-dentistry" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center"> Sedation Dentistry Specialist</h1>
                  <img src={IMG3} className="mt-5" alt="Sedation Dentistry" />
                </div>
              ) : (
                ""
              )}
              {location.pathname === "/services/dentures" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center"> Dentures Specialist</h1>
                  <img src={IMG4} className="mt-5" alt="Dentures" />
                </div>
              ) : (
                ""
              )}
              {location.pathname === "/services/emergency-dentistry" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center"> Emergency Dentistry Specialist</h1>
                  <img src={IMG5} className="mt-5" alt="Emergency Dentistry" />
                </div>
              ) : (
                ""
              )}
              {location.pathname === "/services/family-dentistry" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center">Family Dentistry Specialist</h1>
                  <img src={IMG6} className="mt-5" alt="Family Dentistry" />
                </div>
              ) : (
                ""
              )}
              {location.pathname === "/services/fillings" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center">Fillings</h1>
                  <img src={IMG7} className="mt-5" alt="Fillings" />
                </div>
              ) : (
                ""
              )}
              {location.pathname === "/services/tooth-extractions" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center">Tooth Extractions Specialist</h1>
                  <img src={IMG8} className="mt-5" alt="Tooth Extractions" />
                </div>
              ) : (
                ""
              )}
              {location.pathname === "/services/dental-cleaning-and-exam" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center">Dental Cleaning And Exam Specialist</h1>
                  <img src={IMG9} className="mt-5" alt="Dental Cleaning And Exam" />
                </div>
              ) : (
                ""
              )}              
              {location.pathname === "/services/sealants" ? (
                <div>
                  <h1 data-aos="zoom-in-up" data-aos-delay="100" className="h1 text-center">Sealants Specialist</h1>
                  <img src={IMG10} className="mt-5" alt="Sealants" />
                </div>
              ) : (
                ""
              )}
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
