import React from "react";
import IMG1 from "../images/Picture@2x.jpg";

export default function AboutHead() {
  return (
    <section className="relative h-44">
      {/* Dark background */}
      <div
        className="absolute inset-0 bg-color bg-slate-900 pointer-events-none -z-10"
        aria-hidden="true"
      ></div>

      <div className="relative max-w-6xl max-w-8xl mx-auto px-4">
        <div className="pt-32 pb-20 pt-40 pb-44">
          {/* Hero content */}
          <div className="mx-auto max-w-none flex items-center justify-content-center">
            {/* Content */}
            <div className="pt-5">
              <h1 data-aos="zoom-in-up" className="h1 text-center"> Killeen Family Dentistry </h1>
              <p className="text-center">Dentists located in Killeen, TX</p>
              <img src={IMG1} className="mt-5" alt="this is an img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
