import React from "react";
import Contact from "../images/call_talking.svg";
import Email from "../images/Email.svg";
import Address from "../images/location.svg";

export default function ContactHead() {
  return (
    <section className="relative h-44">
      {/* Dark background */}
      <div
        className="absolute inset-0 bg-color bg-slate-900 pointer-events-none -z-10 [clip-path:polygon(0_0,_5760px_0,_5760px_calc(100%_-_352px),_0_100%)]"
        aria-hidden="true"
      ></div>
      <div className="relative max-w-6xl max-w-8xl mx-auto px-4">
        <div className="pt-32 pb-20 pt-40 pb-44">
          <div className="mx-auto max-w-none flex items-center space-x-8 lg:space-x-16 xl:space-x-20 space-y-16 space-y-0 justify-content-center">
            <div className="pt-5">
              <div data-aos="zoom-in-up" data-aos-delay="100" className="pb-4">
                <h1 className="h1 text-center"> Contact Us</h1>
              </div>
              <div className="d-flex flex-mobile-tab mt-5 align-items-center">
                <div className="w-30vw mobile-hidden">
                  <div className="row contact-bg px-5 py-5">
                    <div>
                      <div data-aos="fade-right" data-aos-delay="100" className="d-flex">
                        <img className="mr-2" src={Address} alt="Killeen Family Dentistry Address" />{" "}
                        <b>Address</b>
                      </div>
                      <div className="mt-3 mb-4 d-flex flex-column">
                        <span>3300 E Central Texas Expy #302,</span>
                        <span>Killeen,TX 76543</span>
                      </div>
                    </div>
                    <div>
                      <div data-aos="fade-right" data-aos-delay="200" className="d-flex">
                        <img className="mr-2" src={Contact} alt="Killeen Family Dentistry Contact" />
                        <b> Contact Us</b>{" "}
                      </div>
                      <div className="mt-3 mb-4 d-flex flex-column">
                        <span>Phone (appointments): 254-218-6349</span>
                        <span>Phone (general inquiries): 254-699-6799</span>
                      </div>
                    </div>
                    <div>
                      <div data-aos="fade-right" data-aos-delay="300" className="d-flex">
                        <img className="mr-2" src={Email} alt="Killeen Family Dentistry Email" />{" "}
                        <b>Email Us</b>
                      </div>
                      <span className="mt-3 mb-4 d-flex flex-column">
                      yaschira@killeenfamilydentistry.com
                      </span>
                    </div>
                  </div>
                </div>
                <div className="map-placed">
                  <iframe
                    title="google-map"
                    className="iframe-class"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6833.760990814458!2d-97.710713!3d31.085251!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2547168bc33ebb84!2sKilleen%20Family%20Dentistry!5e0!3m2!1sen!2sus!4v1651147146850!5m2!1sen!2sus"
                    style={{ border: 0 }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
