import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";
const data = [
  {
    key: 1,
    title: "What are crowns for teeth?",
    content:<div className="services-text">
    <p>Crowns are coverings that your dentist puts on damaged teeth to restore their normal function and appearance. When you have crowns on damaged teeth, you have more protection from infections and other problems.</p>
    <p>Dental crowns are available in different materials, although porcelain and resin are common.</p>
  </div>
  },
  {
    key: 2,
    title: "What are bridges for teeth?",
    content:<div className="services-text">
    <p>Bridges replace missing teeth so that you can talk and chew without feeling self-conscious. Dental bridges are required when you have a missing tooth with healthy teeth on either side.</p>
    <p>These bridges are fitted to the remaining teeth on both sides, which serve as anchors. Your dentist reshapes these anchor teeth to prepare them for a bridge.</p>
  </div>
  },
  {
    key: 3,
    title: "Why might I need a crown?",
    content:<div className="services-text">
    <p>Your dentist at Killeen Family Dentistry may recommend getting a crown if you have a tooth that’s damaged, such as a cracked tooth. You might also need a crown for any teeth affected by tooth decay.</p>
    <p>If you're getting a bridge, the healthy teeth on both sides will need crowns to hold it in place. These crowns are made to fit comfortably and look as much like your natural teeth as possible.</p>
  </div>
  },
  {
    key: 4,
    title: "What happens when I get crowns?",
    content:<div className="services-text">
    <p>Your teeth need some preparation before crowns can go on them. Your dentist reshapes the affected teeth first to ensure that the crowns fit securely and comfortably.</p>
    <p>For severely damaged teeth, you might need some filling placed inside to ensure a proper fit for the crown. You’ll get a temporary crown to wear while an off-site lab manufactures your permanent one from dental impressions.</p>
  </div>
  },
  {
    key: 5,
    title: "What happens when I get a bridge?",
    content:<div className="services-text">
    <p>When you come to Killeen Family Dentistry for a dental bridge, your dentist needs to reshape the teeth that will anchor it. You’ll then have crowns placed on these teeth, and your dentist takes impressions of your natural teeth to have a replacement tooth made.</p>
    <p>When your replacement tooth is ready, your dentist sets it in place and attaches it to the teeth on either side.</p>
    <p>If you need more information on bridges and crowns, please contact Killeen Family Dentistry to make an appointment.</p>
  </div>
  },
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-2">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && <><p data-aos="fade-up" className="accordion-content pt-4 pb-1">{content}</p></>}
      </div>
    </div>
  );
};

export default function CrownsBridges() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
              When you have a hole in your tooth due to decay, bacteria can
              enter and lead to an infection. Filling these holes helps prevent
              this from happening and keeps your tooth more intact. Fillings are
              among the most common types of dental care.At Killeen Family
              Dentistry, located in Killeen,Texas,the dental team may recommend
              fillings for patients both young and old who have tooth decay.
            </p>
          </div>
          <div data-aos="fade-left " data-aos-delay="200" className="mt-2rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
