import Header from "../partials/Header";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div>
      {<Header />}
      <section>
        <div className="relative max-w-6xl max-w-8xl mx-auto px-4">
          <div className="v-center">
            {/* Hero content */}
            <div className="mx-auto max-w-none flex flex-column items-center justify-content-center mb-4">
              {/* Content */}
              <h1
                data-aos="zoom-in-up"
                data-aos-delay="100"
                className="text-404"
              >
                404
              </h1>
              <p>
                You didn't break the internet but we can’t find the page you're
                looking for.
              </p>
              <div className="d-flex mt-3rem">
                <Link
                  to="/"
                  className="btn-enroll mx-auto text-white bg-blue ml-6"
                >
                  Return Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
