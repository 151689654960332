import React, { useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ServiceHead from "../partials/ServiceHead";
import Collapse from "../images/Collapse.svg";
import Expand from "../images/Expand.svg";
const data = [
  {
    key: 1,
    title: "Am I going to need full or partial dentures?",
    content:<div className="services-text">
    <p>That all depends on the number of teeth you’re missing.</p>
    <p>If you only have a couple left, the dental team at Killeen Family Dentistry might suggest having them extracted and wearing full dentures. You’ll also need full dentures if you’re missing all of your teeth already.</p>
    <p>If you have multiple teeth missing in one area of your mouth, the dental team might recommend partial dentures.</p>
  </div>
  },
  {
    key: 2,
    title: "Will other people know I’m wearing dentures?",
    content:<div className="services-text">
    <p>Dentures today are much different from dentures that were available decades ago.</p>
    <p>Unless you remove your dentures when other people are around, they shouldn’t notice that you wear them. Today’s dentures resemble a natural set of teeth in form and function as much as possible.</p>
    <p>When you have your dentures in, they might even benefit your appearance by filling in sunken areas of your face.</p>
  </div>
  },
  {
    key: 3,
    title: "Can I eat with dentures in?",
    content:<div className="services-text">
    <p>Yes, you can leave your dentures in while you’re eating, but keep in mind that you might need time to adjust to the way they feel when you chew. Over time, you’ll feel comfortable chewing food while having your dentures in.</p>
    <p>Remember that certain kinds of foods, such as those that are sticky, can cause damage to your dentures, so watch what you eat while wearing them.</p>
  </div>
  },
  {
    key: 4,
    title: "Can I sleep with dentures in?",
    content:<div className="services-text">
    <p>When it comes to leaving your dentures in while you sleep, you should discuss this with your dentist at Killeen Family Dentistry.</p>
    <p>You might be able to leave them in while sleeping at night if you prefer not having to take them out. Keep in mind, however, that sleeping with dentures in could cause your gums to be sore.</p>
  </div>
  },
  {
    key: 5,
    title: "When will I get used to dentures?",
    content:<div className="services-text">
    <p>It’s common to have slight discomfort when you first begin wearing them, although this should subside after a brief period. However, you should alert your dentist at Killeen Family Dentistry know if your dentures cause any severe discomfort.</p>
  </div>
  },
  {
    key: 6,
    title: "How do I care for dentures?",
    content:<div className="services-text">
    <p>Dentures need a good cleaning on a regular basis so that germs don’t accumulate on them. If you don't clean them regularly, you could increase your risk of tooth decay and other oral health problems. Your dentist at Killeen Family Dentistry will give you instructions on proper denture care.</p>
    <p>If you have several missing teeth and need dentures, please contact Killeen Family Dentistry to set up an appointment.</p>
  </div>
  }  
];

const Accordion = ({ key, title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion border-top pt-4 mb-2">
      <div key={key} className="">
        <div
          className="accordion-title d-flex justify-content-between"
          onClick={() => setIsActive(!isActive)}
        >
          <h5 className="h5">{title}</h5>
          <div>
            {isActive ? (
              <img src={Collapse} alt="collapse" />
            ) : (
              <img src={Expand} alt="expand" />
            )}
          </div>
        </div>
        {isActive && <p data-aos="fade-up" className="accordion-content pt-4 pb-1">{content}</p>}
      </div>
    </div>
  );
};

export default function Dentures() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<ServiceHead />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mb-3 mt-4">
            <p className="mb-4 text-center">
            Having several missing teeth makes it hard to do simple things like speaking clearly and chewing, which can affect the quality of your life. You may also lose confidence in your appearance when you have missing teeth in the front of your mouth. Killeen Family Dentistry, located in Killeen, Texas, provides patients with full or partial dentures to help restore tooth function and improve their appearance.
            </p>
          </div>
          <div className="mt-2rem mb-4 d-flex justify-content-center">
            <h2 className="h2">Frequent Questions</h2>
          </div>
          <div className="mb-5">
            {data.map(({ key, title, content }) => {
              return <Accordion title={title} content={content} key={key} />;
            })}
          </div>
        </div>
      </main>
      {<Footer />}
    </div>
  );
}
